var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-layout",
    {
      attrs: {
        wrap: "",
        "justify-start": "",
        "pa-2": "",
        "pa-sm-4": "",
        "pa-lg-8": "",
      },
    },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "", "justify-start": "", "py-4": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "app-border-color",
                      attrs: { outlined: "", rounded: "lg" },
                    },
                    [
                      _c(
                        "v-layout",
                        {
                          attrs: { wrap: "", "justify-start": "", "pa-4": "" },
                        },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs6: "", sm4: "", lg3: "" } },
                            [
                              _c("span", { staticClass: "subtitle-2" }, [
                                _vm._v(" " + _vm._s(_vm.$t("form.name")) + " "),
                              ]),
                              _c("br"),
                              _c("span", { staticClass: "title-3" }, [
                                _vm._v(" " + _vm._s(_vm.ward.name) + " "),
                              ]),
                            ]
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs6: "", sm4: "", lg3: "" } },
                            [
                              _c("span", { staticClass: "subtitle-2" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("form.phone")) + " "
                                ),
                              ]),
                              _c("br"),
                              _c("span", { staticClass: "title-3" }, [
                                _vm._v(" " + _vm._s(_vm.ward.phone) + " "),
                              ]),
                            ]
                          ),
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs6: "",
                                sm4: "",
                                lg3: "",
                                "pt-4": "",
                                "pt-sm-0": "",
                              },
                            },
                            [
                              _c("span", { staticClass: "subtitle-2" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("form.address")) + " "
                                ),
                              ]),
                              _c("br"),
                              _c("span", { staticClass: "title-3" }, [
                                _vm._v(" " + _vm._s(_vm.ward.address) + " "),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-layout",
            { attrs: { wrap: "", "justify-start": "", "pb-4": "" } },
            [_c("v-spacer")],
            1
          ),
          _c(
            "v-layout",
            { attrs: { wrap: "", "justify-start": "", "py-4": "" } },
            [
              _c(
                "v-flex",
                { staticClass: "title-2", attrs: { xs6: "", "pb-4": "" } },
                [_vm._v(" " + _vm._s(_vm.$t("form.shift")) + " ")]
              ),
              _c(
                "v-flex",
                {
                  staticClass: "title-2",
                  attrs: { xs6: "", "pb-4": "", "text-right": "" },
                },
                [
                  _c("AppExcelExport", {
                    attrs: {
                      items: _vm.exportData.list,
                      headings: _vm.exportData.headings,
                      name: _vm.ward.name,
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.getWardShiftsList(true, false)
                      },
                    },
                  }),
                ],
                1
              ),
              _c("v-flex", { attrs: { xs12: "", lg4: "", xl8: "" } }),
              _c(
                "v-flex",
                { attrs: { xs12: "", sm12: "", md12: "", lg8: "", xl4: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "", "justify-end": "" } },
                    [
                      _c(
                        "v-flex",
                        {
                          attrs: {
                            xs6: "",
                            sm6: "",
                            md5: "",
                            "align-self-center": "",
                          },
                        },
                        [
                          _c("AppDatePicker", {
                            attrs: {
                              range: "",
                              placeholder: "pages.schedules.select_period",
                              clearable: "",
                              "hide-details": "",
                            },
                            on: {
                              submit: function ($event) {
                                return _vm.getWardShiftsList(false, false)
                              },
                            },
                            model: {
                              value: _vm.date_range,
                              callback: function ($$v) {
                                _vm.date_range = $$v
                              },
                              expression: "date_range",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        {
                          attrs: {
                            xs6: "",
                            sm6: "",
                            md3: "",
                            "text-right": "",
                            "align-self-center": "",
                            "pa-2": "",
                            "px-2": "",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              placeholder: _vm.$t("filters.type"),
                              items: _vm.SHIFT_STATUS,
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              "item-text": "label",
                              "item-value": "key",
                              clearable: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.getWardShiftsList(false, false)
                              },
                            },
                            model: {
                              value: _vm.filter.status,
                              callback: function ($$v) {
                                _vm.$set(_vm.filter, "status", $$v)
                              },
                              expression: "filter.status",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        {
                          attrs: {
                            xs6: "",
                            sm6: "",
                            md3: "",
                            "text-right": "",
                            "align-self-center": "",
                            "pa-2": "",
                            "px-2": "",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              placeholder: _vm.$t("filters.sort_by"),
                              items: _vm.sortTypes,
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              "item-text": "label",
                              "item-value": "value",
                              clearable: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.getWardShiftsList(false, false)
                              },
                            },
                            model: {
                              value: _vm.filter.sort_by,
                              callback: function ($$v) {
                                _vm.$set(_vm.filter, "sort_by", $$v)
                              },
                              expression: "filter.sort_by",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("AppSimpleTable", {
                    attrs: {
                      headings: _vm.headings,
                      items: _vm.list,
                      pagination: _vm.pagination,
                      "empty-title": _vm.$t("messages.info.no_items", {
                        type: _vm.$t("pages.employees.assignments"),
                      }),
                    },
                    on: { paginate: (val) => (_vm.pagination.page = val) },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }